import React, { useMemo, useState } from 'react'
import { Input, Button, Select, FormItem, FormContainer, Notification, toast, Upload, DatePicker, Spinner, Checkbox, Menu, Tooltip } from 'components/ui'
import { Field, Form, Formik } from 'formik'
import { HiCheck, HiOutlineMinus, HiOutlineSearch, HiOutlineX, HiX } from 'react-icons/hi'
import { components } from 'react-select'
import { useSelector, useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { createArrayWithItemId, formatterInputSelectData, jsonToFormData, orderArrayBy, orderByStoreName, renameFile, showMessageError, storeActive } from 'utils/storeHube/helpers'
import { RichTextEditor, UsersAvatarGroup } from 'components/shared'
import { apiGetUsersAndStores, apiPostAnswerTicket, apiPostTicket, apiTicketExpiration } from 'services/TicketsService'
import { setSelectedTicket, updateTicketList } from '../store/dataSlice'
import { statusResponse } from 'constants/apiResponse.constant'
import { useTranslation } from 'react-i18next'
import { FcImageFile } from 'react-icons/fc'
import { apiGetStoreById } from 'services/StoreService'
import usePermissions from 'utils/hooks/usePermissions'
import { setDialogView, toggleTicketDialog } from '../store/stateSlice'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { apiGetTicketsCategoriesById } from 'services/TicketCategoryTypeServices'
import { apiGetQuestionGroupById } from 'services/QuestionGroupService'
import QuestionGenerator from './QuestionGenerator'
import { filterVisibleQuestions } from 'views/crud/formQuetionsGroup/helpers'
import { useRef } from 'react'
import debounce from 'lodash/debounce'
import { getDescriptionImages } from './ImageResizer'
import { TEXT, SELECTOR, CHECKBOX, RADIO, TEXTAREA, DATE, MENU, TITLE, FILE, NUMBER, OBJECT, STRING } from 'constants/app.constant'
import AddTicketSchedule from './AddTicketSchedule'
import { resetValuesForTicket } from '../store/globalSlice'
import { apiPostTicketSchedule } from 'services/TicketScheduleService'
import { setTicketScheduleList } from 'views/ticketSchedule/store/dataSlice'
import { injectReducer } from 'store'
import ticketScheduleReducer from "../../ticketSchedule/store/index"
import { secondsToMinutes, formatDateInput } from 'utils/storeHube/helpersDate'
import { useNavigate } from 'react-router-dom'
import { deleteKey, getKey, setKey } from 'utils/storeHube/localStorage'


const { DateTimepicker } = DatePicker
const { Control } = components

injectReducer('ticketSchedule', ticketScheduleReducer)
const CustomSelectOption = ({ innerProps, label, data, isSelected }) => {

    return (
        <div
            className={`flex items-center justify-between p-2 ${isSelected ? 'bg-gray-100 dark:bg-gray-500' : 'hover:bg-gray-50 dark:hover:bg-gray-600'}`}
            {...innerProps}
        >
            <div className="flex items-center">
                <UsersAvatarGroup
                    avatarProps={{ className: 'mr-1 rtl:ml-1 cursor-pointer' }}
                    avatarGroupProps={{ maxCount: 1 }}
                    chained={false}
                    users={[data]}
                    size={10}
                />
                <span className="ml-2 rtl:mr-2">{label}</span>
            </div>
            {isSelected && <HiCheck className="text-emerald-500 text-xl" />}
        </div>
    )
}

const CustomControl = ({ children, ...props }) => {
    const selected = props.getValue()[0]
    return (

        <Control {...props}>
            {selected &&
                <UsersAvatarGroup
                    avatarProps={{ className: 'mr-1 ml-2 rtl:ml-1 cursor-pointer' }}
                    avatarGroupProps={{ maxCount: 1 }}
                    chained={false}
                    users={[selected]}
                />
            }
            {children}
        </Control>
    )
}


const CustomSelectOptionSubcategory = ({ innerProps, label, data, isSelected }) => {

    const { t } = useTranslation()

    const slaOptions = [
        { id: 1, value: 0, label: `Sin definir`, },
        { id: 2, value: 60, label: `1 ${t("hour")}`, },
        { id: 3, value: 120, label: `2 ${t("hours")}`, },
        { id: 4, value: 240, label: `4 ${t("hours")}`, },
        { id: 5, value: 300, label: `5 ${t("hours")}`, },
        { id: 6, value: 360, label: `6 ${t("hours")}`, },
        { id: 7, value: 540, label: `9 ${t("hours")}`, },
        { id: 8, value: 600, label: `10 ${t("hours")}`, },
        { id: 9, value: 720, label: `12 ${t("hours")}`, },
        { id: 10, value: 1440, label: `1 ${t("days")}`, },
        { id: 11, value: 2880, label: `2 ${t("days")}`, },
        { id: 12, value: 4320, label: `3 ${t("days")}`, },
        { id: 13, value: 7200, label: `5 ${t("days")}`, },
        { id: 14, value: 10000, label: `7 ${t("days")}`, },
        { id: 15, value: 14400, label: `10 ${t("days")}`, },
        { id: 16, value: 28800, label: `20 ${t("days")}`, },
        { id: 17, value: 43200, label: `30 ${t("days")}`, },
    ]

    function findSlaOptionByValue(valueToFind) {
        const slaSubcategory = slaOptions.find(option => option.value === valueToFind) ?? slaOptions[0];
        return slaSubcategory.label
    }
    return (
        <div
            className={`flex items-center justify-between p-2 ${isSelected ? 'bg-gray-100 dark:bg-gray-500' : 'hover:bg-gray-50 dark:hover:bg-gray-600'}`}
            {...innerProps}
        >
            <div className="flex items-center">
                {/*     <UsersAvatarGroup
                    avatarProps={{ className: 'mr-1 rtl:ml-1 cursor-pointer' }}
                    avatarGroupProps={{ maxCount: 1 }}
                    chained={false}
                    users={[data]}
                    size={10}
                /> */}
                {data.description ?
                    <Tooltip title={data.description} /* placement="bottom" */>
                        <p className="ml-2 rtl:mr-2 cursor-pointer">{label}</p>
                        <p className="ml-2 rtl:mr-2 cursor-pointer font-semibold ">{`Sla: ${findSlaOptionByValue(data.sla)}`}</p>
                    </Tooltip> :
                    <>
                        <span className="ml-2 rtl:mr-2 cursor-pointer">{` ${label}`}</span>
                        <p className="ml-2 rtl:mr-2 cursor-pointer font-semibold">{`Sla: ${findSlaOptionByValue(data.sla)}`}</p>
                    </>
                }
            </div>
            {isSelected && <HiCheck className="text-emerald-500 text-xl" />}
        </div>
    )
}

const CustomControlSubcategory = ({ children, ...props }) => {
    const selected = props.getValue()[0]
    return (

        <Control {...props}>
            {/*      {selected &&
                <UsersAvatarGroup
                    avatarProps={{ className: 'mr-1 ml-2 rtl:ml-1 cursor-pointer' }}
                    avatarGroupProps={{ maxCount: 1 }}
                    chained={false}
                    users={[selected]}
                />
            } */}
            {children}
        </Control>
    )
}

const AddTicket = () => {


    const { t } = useTranslation()
    const { checkPermission } = usePermissions()
    const dispatch = useDispatch()
    const ticketList = useSelector(state => state.ticketList.data.ticketList)

    const QUESTION_PRIMARY_KEY = `question_group_primary`

    const [loadingStores, setLoadingStores] = useState(false)
    const [loadingTicketExpiration, setLoadingTicketExpiration] = useState(false)
    const [ticketUsers, setTicketUsers] = useState([])
    const { loadingGlobalTicket, ticketParentCategories, ticketSubCategories, ticketStores, storeForTicket, parentIdForTicket, deviceForTicket, recordForTicket, addTicketFromCameras, secondsForTicket, showTicketForm } = useSelector(state => state.ticketList.global)
    const { records, cameras, store } = useSelector(store => store.camerasStoreData.data)
    const { mainRecord, secondsRecord } = records
    const { ticketScheduleList } = useSelector(state => state.ticketSchedule.data)

    const [ticketExpiredAt, setTicketExpiredAt] = useState(null)
    const [categoriesOptions, setCategoriesOptions] = useState([])
    const [files, setFiles] = useState([])
    const [ticketCategoryById, setTicketCategoryById] = useState(null)
    const [questionGroup, setQuestionGroup] = useState(null)
    const [questionGroupId, setQuestionGroupId] = useState(null)
    const [questionLoading, setQuestionLoading] = useState(false)
    const [answers, setAnswer] = useState([])
    const [multiTicktes, setTicketsMultiple] = useState(false)
    const [dataUsersAndStores, setDataUsersAndStores] = useState([])
    const [usersMultipleTickets, setUsersMultipleTicktes] = useState([])
    const [userMultipleTicketsId, setUsersMultipleTicktesId] = useState("")
    const [storesMultipleTickets, setStoresMultipleTickets] = useState([])
    const [multiStores, setMultiStores] = useState([])
    const [checkedAllStores, setCheckedAllStores] = useState(false)
    const [searchResults, setSearchResults] = useState([]);
    const [showTicketScheduleForm, setShowTicketScheduleForm] = useState(false)


    const navigate = useNavigate()

    const tickets_can_attachment = checkPermission('tickets.can_attachment')
    const ticket_schedule_can_store = checkPermission("ticket_schedule.can_store")

    const search_expiration_date = !showTicketScheduleForm
    const search_question_group = !showTicketScheduleForm


    const getUsers = (array) => {
        return array.map(obj => obj.user);
    }

/*     function getUserById(array, id, ticketStores ) {
        const user = array.find(obj => obj.user.id === id);
        console.log(user.stores)
        return user ? user.stores : null;
    } */

        function getUserById(array, id, ticketStores) {
            const user = array.find(obj => obj.user.id === id);
            if (!user) return null;
            
            // Filtrar solo las tiendas que existen en ambos arrays
            const matchingStores = user.stores.filter(userStore => 
                ticketStores.some(ticketStore => ticketStore.id === userStore.id)
            );
            
            return matchingStores;
        }

        
    useEffect(() => {
        multiTicktes && apiGetUsersAndStores().then((res) => {
            setDataUsersAndStores(res.data.data)
            setUsersMultipleTicktes(getUsers(res.data.data))
        })
    }, [multiTicktes])

    useEffect(() => {
        userMultipleTicketsId && setStoresMultipleTickets(modifyAndSortArray(getUserById(dataUsersAndStores, userMultipleTicketsId, ticketStores)))
    }, [userMultipleTicketsId])

    const setCategoryTicket = (parentId) => {
        const list = ticketSubCategories.filter(s => s?.parent_id === parentId)
        setCategoriesOptions(list)
    }

    const stores = storeActive(orderByStoreName(formatterInputSelectData(ticketStores)))

    useEffect(() => {
        addTicketFromCameras && getStoreUsers(storeForTicket.id)
    }, [])



    function sortQuestionsByPriority(obj) {
        if (obj) {
            const sortedObj = JSON.parse(JSON.stringify(obj));
            sortedObj.questions.sort((a, b) => a.priority - b.priority);
            return sortedObj;
        }
    }

    useEffect(() => {
        if (search_question_group) {
            ticketCategoryById && apiGetTicketsCategoriesById(ticketCategoryById).then((res) => {
                const { data } = res
                if (res.data) {
                    setQuestionGroupId(data.data.question_group_primary_id);
                    setQuestionGroup(sortQuestionsByPriority(data.data.question_group_primary[0]))
                    setQuestionLoading(true)
                }
            }
            )
            setQuestionLoading(false)
        }
    }, [ticketCategoryById])



    const getTicketCategoriExpiration = async (body) => {
        if (search_expiration_date) {
            const { store_id, ticket_category_id } = body
            if (store_id && ticket_category_id) {
                setLoadingTicketExpiration(true)
                try {
                    const { data } = await apiTicketExpiration(body)
                    setTicketExpiredAt(data.expired_at)
                } catch (error) {
                    console.log(error);
                }
                setLoadingTicketExpiration(false)
            }
        }
    }

    const getStoreUsers = async (storeId) => {
        setTicketUsers([])
        setLoadingStores(true)
        try {
            const resStores = await apiGetStoreById(storeId)
            const { props } = resStores.data.data
            const users = formatterInputSelectData(props.users_allowed)
            setTicketUsers(users)
        } catch (error) {
            console.log(error);
        }
        setLoadingStores(false)

    }

    // answers

    const [answersCheckBox, setAnswersCheckBox] = useState([]);
    const [filesQuestions, setFilesQuestions] = useState([])


    const onUploadFilesQuestion = (file, id) => {
        const files = Array.isArray(file) ? file : [file]
        setFilesQuestions((prevState) => {
            let updatedFiles = [...prevState];
            // Verificar si ya existe un archivo con el mismo id
            const existingFileIndex = prevState.findIndex((item) => item.id === id);
            if (existingFileIndex !== -1) {
                // Reemplazar el archivo existente
                updatedFiles[existingFileIndex] = { id, files: renameFile(files) };
            } else {
                // Agregar un nuevo archivo
                updatedFiles = [...updatedFiles, { id, files: renameFile(files) }]
            }
            return updatedFiles
        });
        return files
    };

    const removeFileState = (file, id) => {
        let questionsFile = structuredClone(filesQuestions)
        const fileIndex = filesQuestions.findIndex((item) => item.id === id);
        let answerFile = questionsFile[fileIndex]
        const filesFilter = answerFile.files?.filter(f => f.lastModified !== file.lastModified)
        if (filesFilter.length < 1) {
            questionsFile = questionsFile.filter((q, index) => index !== fileIndex)
        } else {
            answerFile.files = filesFilter
        }
        setFilesQuestions(questionsFile)
        return filesFilter
    }

    const setFilesValues = (question, values, type) => {
        let response = undefined
        if (type === 'change_files') {
            response = onUploadFilesQuestion(values, question)
        }
        if (type === 'remove_file') {
            response = removeFileState(values, question)
        }
        return response.length > 0 ? response : undefined
    }

    const createAnswers = (response) => {
        const { question_id, id } = response;
        const objKey = { [question_id]: id }
        const responseObj = { ...answersCheckBox };
        const objFound = answersCheckBox.find(obj => JSON.stringify(obj) === JSON.stringify(objKey));
        if (objFound) {
            delete responseObj[objKey];
            const newanswersCheckBox = answersCheckBox.filter(obj => JSON.stringify(obj) !== JSON.stringify(objKey));
            setAnswersCheckBox(newanswersCheckBox)
        } else {
            responseObj[objKey] = { [question_id]: id };
            setAnswersCheckBox([...answersCheckBox, responseObj[objKey] = { [question_id]: id }]);
        }

    }

    const isSelect = (id) => {
        const select = questionGroup.questions.find((item) => item.id === id)
        if (select.question_type_id === 2 || select.question_type_id === 3 || select.question_type_id === 4 || select.question_type_id === 7) return true
        else return false
    }



    const getFormInLS = () => {
        const ls = getKey(QUESTION_PRIMARY_KEY)
        if (ls) {
            return JSON.parse(ls)
        } else {
            setKey(QUESTION_PRIMARY_KEY, JSON.stringify({}))
            return {}
        }
    }

    const saveFormInLocalStorage = (data) => {
        const { question, type, value } = data

        let questionGroup = getFormInLS()
        let newValue = value

        if (questionGroup) {
            if (type === FILE) {
                return
            }
            if (type === CHECKBOX) {
                newValue = newValue ? JSON.parse(newValue) : undefined
            }
            questionGroup[question] = newValue
            setKey(QUESTION_PRIMARY_KEY, JSON.stringify(questionGroup))
        }
    }


    const viewTicket = (id) => {
        navigate(`/tickets/inbox?ticket=${id}`)
    }

    const handleCopyClick = (id) => {
        navigator.clipboard.writeText(`${window.location.origin}/tickets/inbox?ticket=${id}`)
        toast.push(
            <Notification title={t('copied')} type="success" duration={1000} />
            , {
                placement: 'top-center'
            })
    }


    const submitQuestions = () => {
        const arrayOfAnswersLS = [];

        try {
            /* if (answersCheckBox.length > 0) {
                answersCheckBox.forEach(function (obj) {
                    let key = Object.keys(obj)[0];
                    let value = obj[key];
                    let newObj = {
                        id: key,
                        question_option_id: value
                    };
                    arrayOfAnswers.push(newObj);
                });
            } */

            const formLS = getFormInLS()
            const formValues = Object.keys(formLS).map(key => ({
                key,
                value: formLS[key],
                type: typeof (formLS[key])
            }));

            formValues.map(answer => {

                if (answer.type === STRING) {
                    arrayOfAnswersLS.push({
                        id: answer.key,
                        value: answer.value.trim(),
                    })
                }
                if (answer.type === NUMBER) {
                    arrayOfAnswersLS.push({
                        id: answer.key,
                        question_option_id: answer.value
                    })
                }

                if (answer.type === OBJECT) {
                    answer.value.map(value => value && arrayOfAnswersLS.push({
                        id: answer.key,
                        question_option_id: value
                    }))
                }
            })


            const fileAnswer = filesQuestions.reduce((result, obj) => {
                const filesArray = obj.files.map(f => ({ id: obj.id.toString(), file: f }));
                return result.concat(filesArray);
            }, []);
            arrayOfAnswersLS.push(...fileAnswer)

        } catch (error) {
            console.log(error);
        }
        return arrayOfAnswersLS
    };


    const onUpload = (file, type) => {
        let list = file
        if (type === 'remove_file') {
            list = files.filter(f => f.lastModified !== file.lastModified)
        }
        setFiles(list)
    }

    const [initialValuesForm, setInitialValuesForm] = useState({
        store_id: addTicketFromCameras && storeForTicket ? formatterInputSelectData(storeForTicket) : "",
        ticket_category_id: "",
        parent_category: "",
        operator_id: "",
        description: "",
        sample_since: null,
        sample_until: null,
        record_id: addTicketFromCameras && recordForTicket ? recordForTicket.id : null,
        device_id: addTicketFromCameras && deviceForTicket ? deviceForTicket.id : null,
        second_start: null,
    })

    function addPropertiesToInitialValues(initialValues, questions) {
        const newInitialValues = { ...initialValues };
        const formLs = getFormInLS()
        questions.forEach((question) => {
            let value = formLs[question.id]
            const defaultValue = question.question_type_id === 4 ? (value ?? "") : (value ?? undefined); // define un valor predeterminado basado en el tipo de pregunta
            const propertyName = question.id; // crea el nombre de propiedad basado en el id de la pregunta
            newInitialValues[propertyName] = defaultValue;
        });

        setInitialValuesForm(newInitialValues);
    }

    useEffect(() => {
        questionGroup && addPropertiesToInitialValues(initialValuesForm, questionGroup?.questions)
    }, [questionGroup])



    const dynamicValidationSchema = (data) => {
        let validation = Yup.object(); // Crea una validación vacía
        data && data.forEach((item) => {

            let rule = Yup.string().max(400, 'El campo debe ser menor que 400 caracteres')

            if (item.question_type_id === TEXT || item.question_type_id === TEXTAREA) {
                validation.shape({
                    // Concatena una nueva validación a la anterior
                    [item.id]: rule,
                });
            }

            if (item.question_type_id === CHECKBOX || item.question_type_id === FILE) {
                rule = Yup.array()
                validation.shape({
                    [item.id]: rule,
                });
            }

            if (item.required) { // Si el objeto tiene la propiedad "required" como "true"
                validation = validation.shape({ // Concatena una nueva validación a la anterior
                    [item.id]: rule.required('Esta pregunta es obligatoria'),

                });
            }
        });

        // Agrega las validaciones existentes al objeto de validación dinámica
        validation = validation.shape({
            parent_category: Yup.object().required('Seleccione una categoria'),
            ticket_category_id: Yup.object().required('Seleccione una subcategoria'),
            /* store_id: Yup.object().required('Seleccione una tienda'), */
            store_id: Yup.mixed().required('Seleccione una tienda'),
            description: Yup.string().nullable(),
            operator_id: Yup.object().required('Seleccione un operador'),
            record_id: Yup.number().nullable().notRequired(),
            device_id: Yup.number().nullable().notRequired(),
            second_start: Yup.number().nullable().notRequired(),
            sample_since: Yup.date().nullable().notRequired(),
            sample_until: Yup.date().when('sample_since', (sample_since, validationSchema) => {
                if (sample_since) {
                    return validationSchema.min(sample_since, 'La fecha debe ser mayor que la fecha de inicio');
                }
                return validationSchema;
            }).nullable().notRequired(),
        });

        if (showTicketScheduleForm) {
            validation = validation.shape({
                title: Yup.string().nullable(),
                recurrence: Yup.string().required('Este campo es requerido'),
                start_time: Yup.date().required('Este campo es requerido')
                    .min(
                        new Date(new Date().getTime() + 60 * 60 * 1000),
                        'La fecha y hora de inicio deben ser al menos 1 hora mayor que la hora actual'
                    ),
                end_time: Yup.date().when('start_time', (start_time, validationSchema) => {
                    if (start_time) {
                        return validationSchema.min(start_time, 'La fecha debe ser mayor que la fecha de inicio');
                    }
                    return validationSchema;
                }).required('Este campo es requerido')
            })
        }

        return validation;
    };


    const postTicketScheduled = async (data, setSubmitting) => {
        const payload = {
            stores: data.stores,
            description: data.description,
            operator_id: data.operator_id.id,
            ticket_category_id: data.ticket_category_id.id,
        }
        const body = {
            start_time: formatDateInput(data.start_time),
            end_time: formatDateInput(data.end_time),
            payload: payload,
            title: data.title,
            recurrence: data.recurrence,
            ticket_category_id: data.ticket_category_id.id
        }

        try {
            const { status, data } = await apiPostTicketSchedule(body)
            if (status === statusResponse.CREATE) {
                dispatch(setTicketScheduleList([data.data, ...ticketScheduleList]))
                dispatch(toggleTicketDialog(false));
                toast.push(
                    <Notification title={t("created")} type="success" duration={2500}>
                        {t("ticket_schedule_created")}
                    </Notification>
                );
            }
        } catch (error) {
            showMessageError(error.response.data.message);
        }
        setSubmitting(false)

    }



    const onSubmit = async (formValue, setSubmitting, resetForm) => {
        const {
            store_id,
            operator_id,
            ticket_category_id,
            sample_since,
            sample_until,
            record_id,
            device_id,
        } = formValue;

        let description = formValue.description
        let ticketFiles = files

        if (description) {
            //Recupero las imagenes de la descripcion y las paso como file
            const descriptionData = await getDescriptionImages(description)
            description = descriptionData.elements
            ticketFiles = [...ticketFiles, ...descriptionData.files]
        }

        const stores = multiStores.length > 0 ? createArrayWithItemId(multiStores) : createArrayWithItemId(store_id)

        setSubmitting(true);
        if (showTicketScheduleForm) {
            postTicketScheduled({ ...formValue, description, stores, files: ticketFiles }, setSubmitting)
            return
        }

        const ticketFormData = new FormData();
        ticketFormData.append("operator_id", operator_id.id);
        ticketFormData.append("ticket_category_id", ticket_category_id.id);
        description && ticketFormData.append("description", description);
        sample_since &&
            ticketFormData.append(
                "sample_since",
                dayjs(sample_since).format("YYYY-MM-DD HH:mm:ss")
            );
        sample_until &&
            ticketFormData.append(
                "sample_until",
                dayjs(sample_until).format("YYYY-MM-DD HH:mm:ss")
            );
        ticketExpiredAt &&
            ticketFormData.append(
                "expired_at",
                dayjs(ticketExpiredAt).format("YYYY-MM-DD HH:mm:ss")
            );
        record_id && ticketFormData.append("record_id", record_id);
        device_id && ticketFormData.append("device_id", device_id);
        secondsForTicket > 0 && ticketFormData.append("second_start", secondsForTicket);
        parentIdForTicket && ticketFormData.append("parent_id", parentIdForTicket)

        ticketFiles?.length &&
            ticketFiles.map((file) => {
                ticketFormData.append("files[]", file);
            });

        const answerQuestion = {
            question_group_id: questionGroupId,
            questions: submitQuestions(),
        };

        try {
            const requests = stores.map(async (storeId) => {

                const formData = new FormData();

                for (const [key, value] of ticketFormData.entries()) {
                    formData.append(key, value);
                }
                formData.append("store_id", storeId);

                const { status, data } = await apiPostTicket(formData);
                if (status === statusResponse.CREATE) {
                    questionGroupId && apiPostAnswerTicket(data.data.id, jsonToFormData(answerQuestion));
                    return data.data;
                }
            });

            const results = await Promise.all(requests);
            const createdTickets = results.filter((ticket) => ticket !== undefined);


            if (createdTickets.length > 0) {
                setSubmitting(false);
                deleteKey(QUESTION_PRIMARY_KEY)
                resetForm(true);
                setFiles([]);
                dispatch(updateTicketList([...createdTickets, ...ticketList]));

                toast.push(
                    <Notification title={t("created")} type="success" duration={5000}>
                        {t("ticket_successCreated")}
                        {createdTickets.length === 1 &&
                            <div className='flex gap-3 justify-between mt-2'>
                                <span className='font-semibold cursor-pointer text-indigo-600 dark:text-white' onClick={() => { viewTicket(createdTickets[0].id) }}>{t("ticket_watch")}</span>
                                <span className='font-semibold cursor-pointer text-indigo-600 dark:text-white' onClick={() => { handleCopyClick(createdTickets[0].id) }}>{t("copy_link")}</span>
                            </div>
                        }
                    </Notification>
                );
            }
        } catch (error) {
            setSubmitting(false);
            console.log(error)
            showMessageError(error.response.data.message);
        }


        dispatch(resetValuesForTicket())
        dispatch(toggleTicketDialog(false));
    };

    const onCheckIsMultiple = (value) => {
        setTicketsMultiple(value)
        setInitialValuesForm(initialValuesForm)
    }

    const handleToggle = (expanded, e) => { }

    const selectAllStores = (e) => {
        setCheckedAllStores(!checkedAllStores);
        const updatedStores = storesMultipleTickets.map((store) => ({
            ...store,
            checked: !checkedAllStores,
        }));
        if (!checkedAllStores) setMultiStores(updatedStores)
        else setMultiStores([])
        setStoresMultipleTickets(updatedStores);

    }
    // storesMultipleTickets muestra todas las tiendas que se pueden seleccionar. 
    // multiStores es el array que se agregan las tiendas seleecionadas. 
    // searchResults es el array que muestra las tiendas filtradas. 

    const selectedStores = (item) => {
        const selectedStoreIndex = multiStores.findIndex(store => store.id === item.id);
        const storesMultipleTicketsIndex = storesMultipleTickets.findIndex(store => store.id === item.id);

        if (selectedStoreIndex !== -1) {
            setCheckedAllStores(false);
            const updatedSelectedStores = multiStores.filter((store, index) => index !== selectedStoreIndex);
            setMultiStores(updatedSelectedStores);

            if (storesMultipleTicketsIndex !== -1) {
                const updatedStoresMultipleTickets = [...storesMultipleTickets];
                updatedStoresMultipleTickets[storesMultipleTicketsIndex] = { ...item, checked: false };
                setStoresMultipleTickets(updatedStoresMultipleTickets);
            }

            if (searchResults) {
                const searchResultsIndex = searchResults.findIndex(store => store.id === item.id);
                if (searchResultsIndex !== -1) {
                    const updatedSearchResults = [...searchResults];
                    updatedSearchResults[searchResultsIndex] = { ...item, checked: false };
                    setSearchResults(updatedSearchResults);
                }
            }
        } else {
            const updatedItem = { ...item, checked: true };
            setMultiStores([...multiStores, updatedItem]);

            if (storesMultipleTicketsIndex !== -1) {
                const updatedStoresMultipleTickets = [...storesMultipleTickets];
                updatedStoresMultipleTickets[storesMultipleTicketsIndex] = { ...item, checked: true };
                setStoresMultipleTickets(updatedStoresMultipleTickets);
            }

            if (searchResults) {
                const searchResultsIndex = searchResults.findIndex(store => store.id === item.id);
                if (searchResultsIndex !== -1) {
                    const updatedSearchResults = [...searchResults];
                    updatedSearchResults[searchResultsIndex] = { ...item, checked: true };
                    setSearchResults(updatedSearchResults);
                }
            }
        }
    };

    const modifyAndSortArray = (arr) => {
        const modifiedArray = arr.map(item => ({ ...item, checked: false }))
            .filter(item => item.visible)
            .sort((a, b) => a.label.localeCompare(b.label));
        return modifiedArray;
    }

    const searchInput = useRef()

    const handleDebounceFn = debounce(query => {
        const lowercaseQuery = query.toLowerCase();
        const result = storesMultipleTickets.filter(user => {
            const data = JSON.stringify(user).toLowerCase();
            return data.includes(lowercaseQuery);
        });
        setSearchResults(result);
    }, 500);

    const onSearch = e => {
        const query = e.target.value;
        if (query === '') {
            setSearchResults([]);
        } else {
            handleDebounceFn(query);
        }
    };

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [2, 3, 4, false] }],
                    [{ 'size': ['small', false, 'large'] }],
                    ["bold", "italic", "underline"],
                    [{ color: [] }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
                    [{ 'align': [] }],
                    /*   ["link" ], */
                    ["clean"],
                ],
            },
            clipboard: {
                matchVisual: true,
            },
        }),
    );


    return (
        <div className='mt-6'>
            <h5 className='ml-4'>{t('ticket_capitalize')}</h5>
            {
                addTicketFromCameras &&
                <p className="ml-4 my-2">
                    <span className="font-semibold text-gray-900 dark:text-gray-100">{deviceForTicket.location} </span>
                    {secondsForTicket > 0 && <>
                        {t('in_minute')} <span className="font-semibold text-gray-900 dark:text-gray-100">{secondsToMinutes(secondsForTicket)}</span>
                    </>
                    }
                </p>
            }

            <div className='h-[500px] max-h-[550px] overflow-auto'>
                <Formik
                    initialValues={initialValuesForm}
                    validationSchema={dynamicValidationSchema(questionGroup?.questions)}
                    onSubmit={(values, { resetForm, setSubmitting, }) => {
                        onSubmit(values, setSubmitting, resetForm)
                    }}
                >
                    {({ touched, errors, values, resetForm, isSubmitting }) => (
                        <Form className='m-4 h-[500px] max-h-[550px]'>
                            <FormContainer>

                                {
                                    ticket_schedule_can_store &&
                                    <AddTicketSchedule props={{ initialValuesForm, touched, errors, values, setInitialValuesForm, showTicketScheduleForm, setShowTicketScheduleForm }} />
                                }

                                <div className="mb-2 mt-4 flex flex-row items-center justify-start ">
                                    <FormItem >
                                        <Field name="operator_id">
                                            {({ field, form }) => (
                                                <Checkbox
                                                    /*  onChange={onCheckIsMultiple} */
                                                    onChange={value => {
                                                        onCheckIsMultiple(value)
                                                        form.setFieldValue("operator_id", "")
                                                        form.setFieldValue("store_id", "")
                                                        form.setFieldValue("parent_category", "")
                                                        form.setFieldValue("ticket_category_id", "")
                                                        setQuestionGroup(null)
                                                    }}

                                                    defaultChecked={multiTicktes}
                                                    name="multi_tickets"
                                                >

                                                    <p className="form-label mr-4">{t("multiple_tickets")}</p>
                                                </Checkbox>
                                            )}
                                        </Field>
                                    </FormItem>

                                </div>

                                {
                                    multiTicktes ?
                                        <>
                                            <FormItem
                                                label={t('assign_to')}
                                                invalid={errors.operator_id && touched.operator_id}
                                                errorMessage={errors.operator_id}
                                            >
                                                <Field name="operator_id">
                                                    {({ field, form }) => (
                                                        <Select
                                                            value={values.operator_id}
                                                            placeholder={t('assign_to')}
                                                            options={usersMultipleTickets}
                                                            components={{
                                                                Option: CustomSelectOption,
                                                                Control: CustomControl
                                                            }}
                                                            onChange={user => {
                                                                form.setFieldValue(field.name, user)
                                                                form.setFieldValue("store_id", "")
                                                                setUsersMultipleTicktesId(user.id)
                                                            }}
                                                        ></Select>
                                                    )}
                                                </Field>
                                            </FormItem>
                                            {userMultipleTicketsId && (
                                                storesMultipleTickets.length > 0 ?

                                                    <FormItem
                                                        label={t('stores')}
                                                        invalid={errors.store_id && touched.store_id}
                                                        errorMessage={errors.store_id}
                                                    >
                                                        <p className='mb-2'>{t("stores_selected")} {multiStores.length} </p>
                                                        <Field name="store_id" >
                                                            {({ field, form }) => (
                                                                <>
                                                                    <div className='ml-8 flex justify-between items-center mb-5'>

                                                                        <Checkbox
                                                                            onClick={selectAllStores}
                                                                            checked={checkedAllStores}
                                                                            onChange={store => {
                                                                                form.setFieldValue("store_id", store);
                                                                            }}>
                                                                            <span className='font-semibold text-sm'>{t("selected_all_stores")}</span>
                                                                        </Checkbox>

                                                                        <Input
                                                                            ref={searchInput}
                                                                            className="max-w-md md:w-52 md:mb-0 mb-4 capitalize"
                                                                            size="sm"
                                                                            placeholder={t('search')}
                                                                            prefix={<HiOutlineSearch className="text-lg" />}
                                                                            onChange={onSearch}
                                                                        />
                                                                    </div>
                                                                    <Menu variant="transparent" className="mb-6">
                                                                        <Menu.MenuCollapse
                                                                            eventKey="item-3"
                                                                            label={t("add_stores")}
                                                                            onToggle={handleToggle}
                                                                        >
                                                                            {
                                                                                (searchResults.length > 0 ? searchResults : storesMultipleTickets).map((store) => (
                                                                                    <Menu.MenuItem eventKey={store.address} key={store.id}>
                                                                                        <Checkbox
                                                                                            onClick={() => selectedStores(store)}
                                                                                            checked={store.checked}
                                                                                            onChange={store => {
                                                                                                form.setFieldValue("store_id", store);
                                                                                            }}
                                                                                        >
                                                                                            {store.label}
                                                                                        </Checkbox>
                                                                                    </Menu.MenuItem>
                                                                                ))
                                                                            }
                                                                        </Menu.MenuCollapse>

                                                                    </Menu>

                                                                </>
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                    :
                                                    <p className='text-red-500 mb-4'>{t("message_error_stores")}</p>



                                            )
                                            }
                                        </> :
                                        <>
                                            <FormItem
                                                label={t('store_capitalize')}
                                                invalid={errors.store_id && touched.store_id}
                                                errorMessage={errors.store_id}
                                            >
                                                <Field name="store_id">
                                                    {({ field, form }) => (
                                                        <Select
                                                            value={values.store_id}
                                                            placeholder={t('selectStore')}
                                                            options={stores}
                                                            isLoading={loadingGlobalTicket}
                                                            onChange={store => {
                                                                form.setFieldValue(field.name, store)
                                                                form.setFieldValue('operator_id', "")
                                                                getStoreUsers(store.id)
                                                                getTicketCategoriExpiration({ store_id: store.id, ticket_category_id: form.values?.ticket_category_id?.id })
                                                            }}
                                                        ></Select>
                                                    )}
                                                </Field>
                                            </FormItem>

                                            {values.store_id &&
                                                <FormItem
                                                    label={t('assign_to')}
                                                    invalid={errors.operator_id && touched.operator_id}
                                                    errorMessage={errors.operator_id}
                                                >
                                                    <Field name="operator_id">
                                                        {({ field, form }) => (
                                                            <Select
                                                                value={values.operator_id}
                                                                placeholder={t('assign_to')}
                                                                options={ticketUsers}
                                                                className="mb-4"
                                                                isLoading={loadingStores}
                                                                components={{
                                                                    Option: CustomSelectOption,
                                                                    Control: CustomControl
                                                                }}
                                                                onChange={store => {
                                                                    form.setFieldValue(field.name, store)
                                                                }}
                                                            ></Select>
                                                        )}
                                                    </Field>
                                                </FormItem>
                                            }
                                        </>

                                }

                                {
                                    (values.store_id || multiStores.length > 0) && values.operator_id &&
                                    <FormItem
                                        label={t('category')}
                                        invalid={errors.parent_category && touched.parent_category}
                                        errorMessage={errors.parent_category}
                                    >
                                        <Field name="parent_category">
                                            {({ field, form }) => (
                                                <Select
                                                    field={field}
                                                    value={values.parent_category}
                                                    form={form}
                                                    placeholder={t('selectCategory')}
                                                    options={ticketParentCategories}
                                                    isLoading={loadingGlobalTicket}
                                                    onChange={parentId => {
                                                        setCategoryTicket(parentId.id)
                                                        form.setFieldValue(field.name, parentId)
                                                        form.setFieldValue('ticket_category_id', "")
                                                    }}
                                                >
                                                </Select>
                                            )}
                                        </Field>
                                    </FormItem>
                                }
                                {
                                    values.parent_category &&
                                    <FormItem
                                        label={t('subCategory')}
                                        invalid={errors.ticket_category_id && touched.ticket_category_id}
                                        errorMessage={errors.ticket_category_id}
                                    >
                                        <Field name="ticket_category_id">
                                            {({ field, form }) => (
                                                <Select
                                                    value={values.ticket_category_id}
                                                    placeholder={t('selectSubCategory')}
                                                    options={categoriesOptions}
                                                    onChange={category => {
                                                        form.setFieldValue(field.name, category)
                                                        setTicketCategoryById(category.id)
                                                        getTicketCategoriExpiration({ store_id: form.values.store_id.id, ticket_category_id: category.id })
                                                    }}
                                                    components={{
                                                        Option: CustomSelectOptionSubcategory,
                                                        Control: CustomControlSubcategory
                                                    }}
                                                ></Select>
                                            )}
                                        </Field>
                                    </FormItem>
                                }
                                {values.ticket_category_id && search_expiration_date &&
                                    <FormItem
                                        label={t('expires')}
                                    >
                                        {loadingTicketExpiration
                                            ? <div><Spinner /></div>
                                            : <Field>
                                                {({ field, form }) => (
                                                    <DateTimepicker
                                                        locale="es"
                                                        value={ticketExpiredAt && new Date(ticketExpiredAt)}
                                                        loading={true}
                                                        minDate={new Date()}
                                                        amPm={false}
                                                        inputSize="sm"
                                                        onChange={expired_at => {
                                                            setTicketExpiredAt(new Date(expired_at))
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        }
                                    </FormItem>
                                }
                                {
                                    questionLoading && questionGroup &&
                                    <>
                                        <span className='form-label mb-2' >{questionGroup?.name}</span>

                                        {filterVisibleQuestions(questionGroup.questions).map((question) => (
                                            <FormItem
                                                invalid={question.id.required && errors.question?.id}
                                                errorMessage={errors[question.id]}
                                            >
                                                <Field name={question.id}>
                                                    {({ field, form }) => (
                                                        <QuestionGenerator
                                                            onChange={(val, type) => {
                                                                let updatedAnswers = { ...answers };
                                                                if ([TEXT, SELECTOR, RADIO, MENU, TEXTAREA, DATE].includes(question.question_type_id)) {
                                                                    updatedAnswers[question.id] = val;
                                                                }
                                                                if (question.question_type_id === CHECKBOX) {
                                                                    updatedAnswers[question.id] = val ? JSON.parse(val) : val;
                                                                }
                                                                setAnswer(updatedAnswers);

                                                                if (question.question_type_id !== FILE && field.value === val) {
                                                                    form.setFieldValue(field.name, '');
                                                                } else if (question.question_type_id === FILE) {
                                                                    const valueFiles = setFilesValues(question.id, val, type)
                                                                    form.setFieldValue(field.name, valueFiles);
                                                                } else {
                                                                    form.setFieldValue(field.name, val);
                                                                }
                                                                saveFormInLocalStorage({ question: question.id, type: question.question_type_id, value: val })

                                                            }}
                                                            questions={question}
                                                            createAnswers={createAnswers}
                                                            errorMessage={errors[question.id]}
                                                            onUploadFilesQuestion={onUploadFilesQuestion}
                                                            removeFileState={removeFileState}
                                                        />
                                                    )}
                                                </Field>

                                            </FormItem>
                                        ))}
                                    </>
                                }
                                {
                                    values.ticket_category_id &&
                                    <>
                                        <FormItem
                                            className="mb-8"
                                            labelClass="!justify-start"
                                            label={t('description')}
                                            invalid={errors.description && touched.description}
                                            errorMessage={errors.description}

                                        >
                                            <Field
                                                name="description"
                                                placeholder={t('addDescription')}>
                                                {({ field, form }) => (
                                                    <RichTextEditor
                                                        value={values.description}
                                                        modules={modules}
                                                        onChange={val => {
                                                            form.setFieldValue(field.name, val)
                                                        }}

                                                    />
                                                )}
                                            </Field>
                                        </FormItem>

                                        <FormItem
                                            label={t('since')}
                                            invalid={errors.sample_since && errors.sample_since}
                                            errorMessage={errors.sample_since}
                                        >
                                            <Field name="sample_since">
                                                {({ field, form }) => (
                                                    <DateTimepicker
                                                        locale="es"
                                                        clearButton={
                                                            <Button size="xs">{t('delete')}</Button>
                                                        }
                                                        onChange={since => {
                                                            form.setFieldValue(field.name, since)
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </FormItem>
                                        <FormItem
                                            label={t('until')}
                                            invalid={errors.sample_until && errors.sample_until}
                                            errorMessage={errors.sample_until}
                                        >
                                            <Field name="sample_until">
                                                {({ field, form }) => (
                                                    <DateTimepicker
                                                        field={field}
                                                        form={form}
                                                        locale="es"
                                                        inputSize="sm"
                                                        range
                                                        clearButton={
                                                            <Button size="xs">{t('delete')}</Button>
                                                        }
                                                        onChange={until => {
                                                            form.setFieldValue(field.name, until)
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </FormItem>
                                    </>
                                }
                                <FormItem>
                                    <Field name="file">
                                        {({ field, form }) => (
                                            <Upload
                                                onChange={files => onUpload(files, 'change_files')}
                                                draggable
                                                multiple
                                                uploadLimit={10}
                                                removeFileState={file => onUpload(file, 'remove_file')}
                                            >
                                                <div className="my-16 text-center">
                                                    <div className="text-4xl mb-4 flex justify-center">
                                                        <FcImageFile />
                                                    </div>
                                                    <p className="font-semibold">
                                                        <span className="text-gray-800 dark:text-white">{t('dragFile')}</span>
                                                    </p>
                                                </div>
                                            </Upload>
                                        )}
                                    </Field>
                                </FormItem>

                                <Button
                                    className="fixed top-5 right-20 md:w-40 "
                                    variant="solid"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    {isSubmitting ? t('creating') : t('create')}
                                </Button>
                            </FormContainer>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>

    )

}

export default AddTicket