export const APP_NAME = 'StoreHub'
export const PERSIST_STORE_NAME = 'admin'
export const REDIRECT_URL_KEY = 'redirectUrl'

export const TEXT = 1
export const SELECTOR = 2
export const CHECKBOX = 3
export const RADIO = 4
export const TEXTAREA = 5
export const DATE = 6
export const MENU = 7
export const TITLE = 8
export const FILE = 9

export const NUMBER = "number"
export const OBJECT = "object"
export const STRING = "string"


export const USER_SYS = {
    name: 'Sistema',
    lastname: 'automático',
    image: 'https://cdn-icons-png.flaticon.com/512/1587/1587565.png'
}

export const INIT_STATE_LOCALSTORAGE = {
    theme: {
        themeColor: "indigo",
        direction: "ltr",
        mode: "light",
        locale: "en",
        primaryColorLevel: 600,
        panelExpand: false,
        navMode: "transparent",
        layout: {
            type: "modern",
            sideNavCollapse: false
        }
    },
    auth: {
        session: {
            token: "",
            signedIn: false
        },
        user: {
            id: "",
            uuid: null,
            avatar: "",
            userName: "",
            userLastname: "",
            email: "",
            permissions: [],
            authority: [],
            haveNotifications: false,
            parameters: [],
            timezone: ""
        }
    },
    _persist: {
        version: -1,
        rehydrated: true
    }
};