import React, { useRef, useState } from 'react'
import { Input, Button, Radio, Spinner, Switcher, Tooltip, Badge, Select} from 'components/ui'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { HiOutlinePlusCircle, HiOutlineMinusCircle, HiOutlineTrash, HiOutlineExclamation } from 'react-icons/hi'
import { formatterInputSelectData } from 'utils/storeHube/helpers'
import { filterVisibleQuestions, guidGenerator, handleNameChange, handleOptionChange, onCheckRequired,  removeInvisibleOptions, setOptionIncident, setOptionPenality } from './helpers'
import { FiSave } from 'react-icons/fi'

const QuestionTypeRadio = ({ handleAddNewQuestion, questionTypeId, question, edit, handleRemoveQuestion, isAnswer, questionForAnswer, onChange, errorMessage, defaultValue }) => {

  const { t } = useTranslation()

  const [newOptionEdit, setNewOptionEdit] = useState(false)
  const [optionList, setOptionList] = useState(question ? formatterInputSelectData(question.question_option) : [])
  const [nameQuestion, setNameQuestion] = useState(question ? question.name : "")
  const [uniqueUuid, setUniqueUuid] = useState(question ? (question.uuid || question.uuid2) : "")
  const [optionListModified, setOptionListModified] = useState(question ? question.question_option : [])
  const [questionType_id, setQuestionTypeId] = useState(question ? question.question_type_id : questionTypeId)
  const [questionRequired, setQuestionRequired] = useState(question ? question.required : false)
  const [loading, setLoading] = useState(false)
  const [questionTag, setQuestionTag] = useState(question && question.tags ? question.tags[0] : null)
  const [points, setPoints] = useState(0)
  const [selectedPoints, setSelectedPoints] = useState(null);

  const inputRef = useRef(null)

  useEffect(() => {
    if (newOptionEdit) {
      inputRef.current.focus()
    }
  }, [newOptionEdit])



  const removeOption = (option) => {
    const modifiedOption = { ...option, visible: false };
    const newOptionList = optionList.filter((item) => item.label !== option.label);
    newOptionList.push(modifiedOption);
    setOptionList(newOptionList);
    setOptionListModified(newOptionList);
  };



  const onNewOptionAdd = () => {
    const newUuid2 = guidGenerator(); // Generar uuid2 aleatorio
    const newOption = {
      name: inputRef.current.value,
      label: inputRef.current.value,
      checked: false,
      visible: true,
      points: points,
      uuid2: newUuid2, // Asignar uuid2
    };
    const newOptionModified = {
      name: inputRef.current.value,
      uuid2: newUuid2, // Asignar uuid2
    };
    setOptionList(prevOption => [...prevOption, newOption]);
    setOptionListModified(prevOption => [...prevOption, newOptionModified]);
    setNewOptionEdit(false);
    inputRef.current.value = "";
  }
  const onOptionCheckChange = (checked, index) => {
    setOptionList(prevOption => {
      const mutatedPrevOption = prevOption.map((option, optionIndex) => {
        if (index === optionIndex) {
          option.checked = checked
        }
        return option
      })
      return [...mutatedPrevOption]
    })
  }

  const updateQuestions = () => {
    setLoading(true)
    handleAddNewQuestion(nameQuestion, optionList, questionType_id, questionRequired, uniqueUuid, undefined, undefined, points)
    setPoints(0)
    setSelectedPoints(null)
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }


  useEffect(() => {
    !isAnswer && updateQuestions()
  }, [optionListModified, nameQuestion, questionRequired, optionList])



    


  return (
    <>
      {
        isAnswer ?
          <>
            <div className='flex gap-3'>
              <p className='mb-2'>
                {questionForAnswer && questionForAnswer.name}
                {questionForAnswer.required && <span className="text-red-500">*</span>}
              </p>
              <label className='cursor-pointer' htmlFor={`clear_input_${questionForAnswer.id}`}>
                <HiOutlineTrash size={18} />
              </label>
              <input
                className='hidden'
                id={`clear_input_${questionForAnswer.id}`}
                type="radio"
                name={questionForAnswer.id}
                onChange={() => onChange(undefined)}
              />
            </div>
            <div className="flex-col  w-5/5">

              {filterVisibleQuestions(questionForAnswer.question_option).map((option, index) => (
                <div key={index} className="flex flex-row items-center">
                  <Radio
                    id={option.id}
                    key={index}
                    name={questionForAnswer.id}
                    className="flex flex-row mb-2"
                    onChange={() => onChange(option.id)}
                    value={defaultValue}
                    defaultChecked={defaultValue == option.id}
                  >
                    {option.name}
                  </Radio>
                </div>
              ))}
            </div>
            <span className='text-red-500'> {errorMessage} </span>
          </>
          :
          <>

            <Input onChange={(event) => handleNameChange(setNameQuestion, event)} placeholder={t("question")} value={nameQuestion} />
            {edit && questionTag &&
              <div className=" flex items-center mr-2 rtl:ml-2 mt-3 gap-2">
                <p className=' capitalize '>{t("tag")} : </p>
                <div className="flex items-center gap-2 border rounded-lg px-1">
                  <Badge style={{ "backgroundColor": `${questionTag.color}` }} />
                  <span className={`capitalize font-semibold `}>
                    {questionTag.name}
                  </span>
                </div>
              </div>
            }
            {
              optionList.length > 0 &&
              <div className="flex flex-col">
                <div className='mt-4'>
                  {  removeInvisibleOptions(optionList).map((option, index) => (
                    <div key={index} className="flex flex-col sm:flex-row items-start sm:items-center justify-start sm:justify-between mb-5 ">
                      <div className="flex flex-row items-center justify-between">
                        <div className='mr-5' onClick={() => removeOption(option)}>
                          <HiOutlineMinusCircle className=' text-lg ' />
                        </div>
                        <Radio
                          key={index}
                          defaultChecked={option.checked}
                          className="flex flex-row"
                          onChange={checked => onOptionCheckChange(checked, index)}
                        >
                          {option.label}
                        </Radio>
                        <div className=' ml-2 h-6 flex items-center gap-2 text-center'>
                          {/* Input para cambiar los puntos */}
                          Puntos:
                          <input
                            type="text"
                            className="input h-6 w-[60px] border border-gray-300 p-2 rounded text-center"
                            onChange={(e) => handleOptionChange(e, option.uuid || option.uuid2, 'points', setOptionList, setOptionListModified)}  // Pasa el id y el campo 'points'
                            value={option.points}
                          />
                         

                          {/* Input para cambiar la prioridad */}
                          Orden:
                          <input
                            type="text"
                            className="input h-6 w-[40px] border border-gray-300 p-2 rounded text-center"
                            onChange={(e) => handleOptionChange(e, option.uuid || option.uuid2, 'priority', setOptionList, setOptionListModified)}  // Pasa el id y el campo 'priority'
                            value={option.priority || 0}  // Muestra el valor de prioridad actual o vacío si no existe
                            placeholder="Priority"
                          />
                        </div>

                        {/* <div className='ml-2'>
                          ({option.points} Pts.)
                        </div> */}
                        <div className='ml-5 gap-2  mt-2 '>
                          <Tooltip title={t("message_incidence")}>
                            {option.incidents && <HiOutlineExclamation className=' text-lg ' />}
                          </Tooltip>
                        </div>
                      </div>
                      <div className="flex flex-wrap gap-3 items-end" >
                        <div className='flex items-center mr-2'>
                          <span className='mr-2'> {t("check_incidence")}</span>
                          <Switcher defaultChecked={option.incidents} onChange={(event) => setOptionIncident(setOptionList, event, option.uuid || option.uuid2)} />
                        </div>
                        {
                            removeInvisibleOptions(optionList)[index].incidents &&
                          <div className='flex items-center mr-2 '>
                            <span className='mr-2'> {t("check_penality")}</span>
                            <Switcher defaultChecked={option.penality} onChange={(event) => setOptionPenality(setOptionList, event, option.uuid || option.uuid2)} />
                          </div>
                        }
                      </div>
                    </div>
                  ))}
                </div>

              </div>
            }
            <>
              <div className="flex items-center gap-2">
                <Input ref={inputRef} placeholder={t("question_options")} />
              </div>
              
              <div className="flex items-center gap-2 my-3">
                <Button type="button" onClick={onNewOptionAdd} icon={<HiOutlinePlusCircle />}></Button>
              </div>

              <div className='flex items-center mb-3 justify-end'>
                <div className='flex items-center mr-2'>
                  <span className='mr-2'> {t("isRequired")}</span>
                  <Switcher defaultChecked={question && question.required} onChange={(event) => onCheckRequired(setQuestionRequired, event)} />
                </div>
                {
                  edit &&
                  <>
                    <div className='mr-5 ' onClick={() => handleRemoveQuestion(question)}>
                      <Tooltip title="Borrar pregunta">
                        <HiOutlineTrash className=' text-lg ' />

                      </Tooltip>
                    </div>
                    <div className='mr-5' onClick={() => updateQuestions()}>
                      <Tooltip title="Guardar cambios">
                        {loading ? <Spinner /> : <FiSave className=' text-lg ' />}
                      </Tooltip>
                    </div>
                  </>

                }
              </div>
              {
                !edit && <Button
                  block
                  className="border-dashed mb-3"
                  onClick={() => handleAddNewQuestion(nameQuestion, optionListModified, questionType_id, questionRequired, uniqueUuid)}
                >
                  {t("save_question")}
                </Button>
              }


            </>
          </>
      }
    </>
  )
}

export default QuestionTypeRadio